<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :form_data_seo="form_data_seo"
            :slot_table_list="['operation','price']"
          
            ref="list"
            :rowKey="'id'"
        >
            <template slot="left_btn">
                <a-button
                    @click="add()"
                    type="primary"
                > 添加差旅额度</a-button>
            </template>
            <template slot="price" slot-scope="data">
                <span>
                    {{data.record.price+'元/月'}}
                </span>
            </template>
			<template slot="operation" slot-scope="data">
			    <span>
			        <a @click="edit(data.record)">编辑</a>
			    </span>
				<a-divider type="vertical" />
				<a-popconfirm title="您确定要删除吗?"  @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
			</template>
        </TableList>
        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible"  ref="form" :width='600'>
			<div slot="price">
				<div>
					<span class="price_required">*</span><span>额度级别：</span>
					<a-input v-model="price" type='number' required class='price_input'></a-input>元/月
				</div>
					 
			</div>
			<template slot="rule_user">
				<div>
					  <span class="price_required">*</span><span>适用人员：</span>
				</div>
			    <TransferTree
			        @change="TransferTreeChange"
			        :treeData.sync="treeData"
			        :value.sync="treeDataValue"
			    />
			    <a-input hidden="true" v-decorator="['rule_user']"></a-input>
			</template>
		</EditPop>
    </div>
</template>

<script>
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import SelectUser from "@/components/SelectUser";
import { message } from 'ant-design-vue'
import TransferTree from "@/components/TransferTreeTwo";
import { quotaSettingList,getUserToRole,saveQuotaSetting,quotaSettingDetail,delQuotaSetting} from "@/api/evection";
const columns = [
    {
        title: "ID",
        dataIndex: "id"
    },
	{
	    title: "额度级别",
	    dataIndex: "price",
		scopedSlots: {
		    customRender: "price"
		}
	},
	// price
	{
	    title: "适用人数",
	    dataIndex: "user_num",
		scopedSlots: {
		    customRender: "user_num"
		}
	},
    {
        title: "创建人",
        dataIndex: "user_name"
    },
    {
        title: "最后更新时间",
        dataIndex: "update_time"
    },
    {
        title: "操作",
        dataIndex: "operation",
        scopedSlots: {
            customRender: "operation"
        }
    }
];



export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
        SelectUser,
		TransferTree
    },
    data() {
        return {
            config: this.$config,
            get_table_list: quotaSettingList,
            columns,
			price:0,
			treeData: [],
			id:'',
			treeDataValue: [],
            loading: false,
            visible: false,
            form_data_seo: {
                list: [
                 
                    {
                        type: "text",
                        name: "keyword",
                        title: "额度级别",
                        placeholder: "请输入额度级别",
                        options: {}
                    },
					{
					    type: "range_date",
					    name: "range_date",
					    title: "最后更新时间",
					    options: {
							
						},
					    start: {
					        name: 'start_time'
					    },
					    end: {
					        name: 'end_time'
					    },
					},
                   
                ],
                ...this.$config.form_data_seo
            },
         form_data: {
             title: '添加酒店级别',
             list: [
				{
				    type: 'text',
				    name: 'id',
				    hidden: true,
				    options: {},
				},
                 {
                   type: 'slot',
                     name: 'price',
                     title: '',
						options: {
                        rules: [
                            {
                                required: true,
                                message: ""
                            }
                        ]
                    },
         		},
                {
                    type: "slot",
                    name: "rule_user",
                    title: "",
                   options: {
                   	rules: [{
                   		required: true,
                   		message: "请选择标准类型"
                   	}],
                   	initialValue: "1"
                   },
                },
             ]
         }
        };
    },


    async created() {
		this.get_role()
    },
    methods: {
		get_role(){
			getUserToRole().then(res=>{
				 this.treeData = this.$method.get_user_department_list(res.data.role);
			})
			 
		},
		TransferTreeChange(value) {
			this.rule_user =  value.join(',')
		},
        submit(data) {
			if(this.rule_user){
				let obj = {
					role_user:this.rule_user,
					price:this.price,
					id:this.id
				}
				    saveQuotaSetting({
				        data: obj,
				        info: true
				    }).then(res => {
				         this.$refs.list.get_list();
				        this.visible = false;
						this.treeDataValue=[]
				       
				    })
			}else {
					message.error('请选择适用人员');
				return;
				
			}
			
        },
        add() {
            this.form_data.title = "添加"
			this.form_data.list.forEach(item => {
			    item.options.initialValue = "";
			})
			this.price= 0
            this.visible = true;
        },
		del(record){
			    delQuotaSetting({
			        data:{
						id:record.id
					},
			    }).then(res => {
			        this.$refs.list.get_list();
			    })
		},
        edit(data) {
            this.form_data.title = "编辑"
			this.id=data.id
			quotaSettingDetail({data:{
				id:data.id
			}}).then(res=>{
				let role_data= []
				let treeDataValue = [];
				res.data.list.role_user.map(io => {
				    treeDataValue.push("user_" + io.id)
				    io.key = "user_" + io.id;
					role_data.push( io.key)
				    io.title = io.username;
				    return io;
				});
				this.rule_user=role_data.join(',')
				this.treeDataValue = treeDataValue;
			})
			
			this.price =  data['price']
            this.visible = true;
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.price_input {
	width: 150px;
	margin-right: 20px;
}

.price_required {
	color: red;
	margin-right: 5px;
}
</style>